import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import { registerFormRules } from '../utils/registerPageFormRules';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface RegisterFormProps extends FormProps {
	loading: boolean;
}

const RegisterForm: React.FC<RegisterFormProps> = (props: RegisterFormProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);
	const { loading, ...rest } = props;

	return (
		<Form {...rest}>
			<Form.Item
				name='email_address'
				messageVariables={{ arg: t('form:Common.User.Labels.Email') }}
				rules={registerFormRules.email()}
			>
				<Input placeholder={t('form:Common.User.Placeholders.Email')} />
			</Form.Item>

			<Form.Item
				name='password'
				messageVariables={{ arg: t('form:Common.User.Labels.Password') }}
				rules={registerFormRules.password()}
			>
				<Input type='password' placeholder={t('form:Common.User.Placeholders.Password')} />
			</Form.Item>

			<Form.Item
				name='confirmPassword'
				messageVariables={{ arg: t('form:Common.User.Labels.ConfirmPassword') }}
				rules={registerFormRules.confirmPassword()}
			>
				<Input type='password' placeholder={t('form:Common.User.Placeholders.ConfirmPassword')} />
			</Form.Item>

			{/* Dodać checkbox do regulaminu */}
			{/* <Form.Item name='language' initialValue={i18n.language} hidden>
                <Input hidden />
            </Form.Item>
            <Form.Item name='roles' initialValue={[Role.USER]} hidden>
                <Input hidden />
            </Form.Item> */}

			<Form.Item name='agreement' valuePropName='checked' rules={registerFormRules.agreement()}>
				<Checkbox>
					Przeczytałem i akceptuję <Link to='terms-and-conditions'>regulamin</Link> i{' '}
					<Link to='privacy-policy'>politykę prywatności</Link>
				</Checkbox>
			</Form.Item>

			<Form.Item>
				<Button loading={loading} type='primary' htmlType='submit' size='large'>
					{t('common:Actions.SignUp')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default RegisterForm;
