import { Button, Card, Col, notification, Row } from 'antd';
import { deleteUserAddress, getAccountDetails } from 'App/state/account/account.thunk';
import { RootState } from 'App/state/root.reducer';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

interface EditProfileAddressesTabsProps {
}

const EditProfileAddressesTab: React.FC<EditProfileAddressesTabsProps> = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation(['page', 'common']);

    const accountDetails = useSelector((state: RootState) => state.account.accountDetails);

    useEffect(() => {
        if (!accountDetails)
            dispatch(getAccountDetails());
    }, [dispatch, accountDetails]);

    const handleAddressDelete = (addressId: number) => {

        const onSuccess = () => {
            dispatch(getAccountDetails());

            notification.success({
                message: t('common:Success.Success'),
                description: 'Usunięto adres'

            });
        };

        dispatch(deleteUserAddress(addressId, onSuccess));
    };

    if (accountDetails) {
        return (
            <>
                <Row gutter={[16, 16]}>

                    <Col span={24}>

                        <>
                            {accountDetails.client_information.client_addresses && accountDetails.client_information.client_addresses.map((item, key) =>

                                <Card className='restaurant--container--card' key={item.id}>
                                    <Card.Grid hoverable={false} style={{ width: '100%', textAlign: "center" }}>
                                        <Row>
                                            {/* Adres */}
                                            <Col xs={24} sm={24} md={18} lg={14} xl={14}>
                                                <Row>
                                                    {item.name}
                                                </Row>
                                                <Row justify="start">
                                                    {item.address_1}
                                                </Row>
                                                <Row justify="start">
                                                    {item.address_2}
                                                </Row>
                                                <Row justify="start">
                                                    {item.city}
                                                </Row>
                                                <Row justify="start">
                                                    {item.comment}
                                                </Row>
                                            </Col>

                                            {/* Przyciski do zarządzania */}
                                            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                                <Button
                                                    className="show-menu-button"
                                                    type='default'
                                                    onClick={() => handleAddressDelete(item.id)}
                                                >
                                                    Usuń
											</Button>
                                            </Col>
                                        </Row>


                                    </Card.Grid>
                                </Card>


                            )}
                        </>

                    </Col>
                </Row>
                <Row justify='center'>
                    <Link to={'/account/add-address'} >
                        <Button danger className='add-address-button'>Dodaj adres</Button>
                    </Link>

                </Row>
            </>
        );
    } else {
        return <></>;
    }
};

export default EditProfileAddressesTab;
