import { Input, Button, Form } from 'antd';
import { FormProps } from 'antd/lib/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { changePasswordFormRules } from '../utils/changePasswordFormRules';

interface ChangePasswordFormProps extends FormProps {
	loading: boolean;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (props: ChangePasswordFormProps) => {
	const { t } = useTranslation(['form', 'common']);

	const layout = {
		labelCol: {
			xs: {
				span: 24
			},
			md: {
				span: 8
			}
		},
		wrapperCol: {
			xs: {
				span: 24
			},
			md: {
				span: 8
			}
		}
	};

	const tailLayout = {
		wrapperCol: {
			xs: {
				span: 24
			},
			md: {
				span: 8,
				offset: 8
			}
		}
	};

	const { loading, ...rest } = props;

	return (
		<Form className='px-3' {...layout} {...rest}>

			<Form.Item 
			name='old_password' 
			label={t('form:Common.User.Labels.OldPassword')}
			messageVariables={{ arg: t('form:Common.User.Labels.OldPassword') }} 
			rules={changePasswordFormRules.oldPassword()}>
                <Input
                    type='password'
                />
            </Form.Item>

			<Form.Item 
			name='new_password' 
			label={t('form:Common.User.Labels.NewPassword')}
			messageVariables={{ arg: t('form:Common.User.Labels.NewPassword') }} 
			rules={changePasswordFormRules.newPassword()}>
                <Input
                    type='password'
                />
            </Form.Item>

			<Form.Item 
			name='confirm_new_password' 
			label={t('form:Common.User.Labels.ConfirmNewPassword')}
			messageVariables={{ arg: t('form:Common.User.Labels.ConfirmNewPassword') }} 
			rules={changePasswordFormRules.confirmNewPassword()}>
                <Input
                    type='password'
                />
            </Form.Item>

			<Form.Item {...tailLayout}>
				<Button block loading={loading} type='primary' htmlType='submit'>
					{t('common:Actions.Save')}
				</Button>
			</Form.Item>
			
		</Form>
	);
};

export default ChangePasswordForm;
