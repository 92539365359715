import { DeleteOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Col, Row, Button, Typography, Image } from 'antd';
import { CartProduct } from 'App/domain/entities/cart_product';
import { clearNewOrderState, updateCart } from 'App/state/newOrder/newOrder.slice';
import { RootState } from 'App/state/root.reducer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductImgUrl } from 'App/pages/RestaurantMenuPage/utils/getProductImgUrl';

import './ProductCard.less';

const { Text } = Typography;

const ProductCard = (cartProduct: CartProduct) => {
	const { count, option_items, product } = cartProduct;
	const { id, name, description } = product;

	const dispatch = useDispatch();
	const cart = useSelector((state: RootState) => state.newOrder.cart);

	const updateCount = (countDifference: number) => {
		let tempCartProducts: CartProduct[] = JSON.parse(JSON.stringify(cart.cart_products));

		let tempProduct = tempCartProducts.find((cartProduct) => cartProduct.product.id === id);

		if (tempProduct) tempProduct.count = tempProduct.count + countDifference;

		if (tempProduct.count >= 1) dispatch(updateCart({ ...cart, cart_products: tempCartProducts }));
	};

	const deleteProduct = () => {
		let tempCartProducts: CartProduct[] = JSON.parse(JSON.stringify(cart.cart_products));

		tempCartProducts = tempCartProducts.filter((cartProduct) => cartProduct.product.id !== id);

		dispatch(updateCart({ ...cart, cart_products: tempCartProducts }));

		if (tempCartProducts.length === 0) dispatch(clearNewOrderState());
	};

	const optionItems = option_items?.length > 0 && option_items.map((item) => item.name).join(', ');

	return (
		<Card
			className='mt-2 product-card'
		>
			<Row align='middle'>
				<Col xs={24} md={3}>
					<Row justify='center' align='middle'>
						<Col>
							<Row justify='center'>
								<Image
									src={getProductImgUrl(product)}
									className="product-card-image"
								/>
							</Row>
						</Col>
					</Row>
				</Col>

				<Col xs={24} md={15} className='mt-2'>
					<Row>
						<Col xs={24}>
							<Text strong>{name}</Text>
						</Col>
						<Col xs={24}>
							<Text type='secondary'>{description}</Text>
						</Col>

						{option_items?.length > 0 && (
							<Col xs={24}>
								<Text type='warning' strong>
									{optionItems}
								</Text>
							</Col>
						)}
					</Row>
				</Col>

				<Col xs={24} md={6} className='mt-2'>
					<Row justify='center'>
						<Col xs={24} md={18}>
							<Row justify='center'>
								<Col>
									<Button
										type='primary'
										size='small'
										className="product-card-count-button"
										onClick={() => updateCount(-1)}
										icon={<MinusOutlined />}
									/>
									<Text
										className="product-card-count-text"
									>{count}</Text>
									<Button
										type='primary'
										size='small'
										className="product-card-count-button"
										onClick={() => updateCount(1)}
										icon={<PlusOutlined />}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={24} md={6}>
							<Row justify='end' >
								<Col>
									<Button
										size='small'
										danger
										shape='circle'
										icon={<DeleteOutlined />}
										onClick={deleteProduct}
									/>
								</Col>
							</Row>
						</Col>

					</Row>
				</Col>

			</Row>
		</Card>
	);
};

export default ProductCard;
