import React from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FormProps } from 'antd/lib/form/Form';
import { forgotPasswordFormRules } from '../utils/forgotPasswordFormRules';
import { useTranslation } from 'react-i18next';

interface LoginFormProps extends FormProps {
	loading: boolean;
}

const ForgotPasswordForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);
	const { loading, ...rest } = props;

	return (
		<Form {...rest}>
			<Form.Item
				name='email_address'
				messageVariables={{ arg: t('form:Common.User.Labels.Email') }}
				rules={forgotPasswordFormRules.email()}
			>
				<Input
					prefix={<UserOutlined className='site-form-item-icon' />}
					placeholder={t('form:Common.User.Placeholders.Email')}
				/>
			</Form.Item>
			<Form.Item>
				<Button loading={loading} type='primary' htmlType='submit' size='large'>
					{t('common:Actions.Submit')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default ForgotPasswordForm;
