import agent from 'App/api/agent/agent';
import { AppThunk } from '../store';
import {
	getUserOrdersStart,
	getUserOrdersSuccess,
	getUserOrdersFailure,
	getUserOrderFailure,
	getUserOrderStart,
	getUserOrderSuccess,
	getAnonymousOrderStart,
	getAnonymousOrderSuccess,
	getAnonymousOrderFailure,
} from './orders.slice';

export const getUserOrders = (): AppThunk => async (dispatch) => {
	dispatch(getUserOrdersStart());
	agent.UserOrders.getUserOrders()
		.then((response) => {
			dispatch(getUserOrdersSuccess(response));
		})
		.catch(() => dispatch(getUserOrdersFailure()));
};

export const getUserOrder = (orderId: number): AppThunk => async (dispatch) => {
	dispatch(getUserOrderStart());
	agent.UserOrders.getUserOrder(orderId)
		.then((response) => {
			dispatch(getUserOrderSuccess(response));
		})
		.catch(() => dispatch(getUserOrderFailure()));
};

export const getAnonymousUserOrder = (anonymousOrderId: string): AppThunk => async (dispatch) => {
	dispatch(getAnonymousOrderStart());
	agent.UserOrders.getAnonymousOrder(anonymousOrderId)
		.then((response) => {
			dispatch(getAnonymousOrderSuccess(response));
		})
		.catch(() => dispatch(getAnonymousOrderFailure()));
};
