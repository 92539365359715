import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import { default as NotFoundPage } from './pages/ResultPages/NotFoundPage/NotFoundPageContainer';
import { default as ForbiddenPage } from './pages/ResultPages/ForbiddenPage/ForbiddenPageContainer';
import { default as InternalServerErrorPage } from './pages/ResultPages/InternalServerErrorPage/components/InternalServerErrorPageContainer';

import { default as HomePage } from './pages/HomePage/HomePageContainer';
import { default as LoginPage } from './pages/LoginPage/LoginPageContainer';
import { default as RegisterPage } from './pages/RegisterPage/RegisterPageContainer';
import { default as AccountPage } from './pages/AccountPage/AccountPageContainer';

import { default as RestaurantMenuPage } from './pages/RestaurantMenuPage/RestaurantMenuPageContainer';
import { default as PromotionsPage } from './pages/PromotionsPage/PromotionsPageContainer';
import { default as OrdersPage } from './pages/OrdersPage/OrdersPageContainer';
import { default as CartPage } from './pages/CartPage/CartPageContainer';

import PaymentMaintenanceBreakPage from './pages/CartPage/components/payment/PaymentMaintenanceBreakPage';
import TermsAndConditionsContainer from './pages/LegalPages/TermsAndConditions/TermsAndConditionsContainer';
import PrivacyPolicyContainer from './pages/LegalPages/PrivacyPolicy/PrivacyPolicyContainer';

const Routes: React.FC = () => {
	return (
		<Switch>
			<Route exact path='/' component={HomePage} />
			<Route exact path='/sign-in' component={LoginPage} />
			<Route exact path='/sign-up' component={RegisterPage} />

			<Route path='/account' component={AccountPage} />

			<Route path='/restaurant-menu/:restaurantId?' component={RestaurantMenuPage} />
			<Route path='/promotions' component={PromotionsPage} />
			<Route path='/orders' component={OrdersPage} />
			<Route path='/cart' component={CartPage} />

			<Route path='/payment-maintenance-break' component={PaymentMaintenanceBreakPage} />

			<Route path='/terms-and-conditions' component={TermsAndConditionsContainer} />
			<Route path='/privacy-policy' component={PrivacyPolicyContainer} />

			<Route path='/403' component={ForbiddenPage} />
			<Route path='/404' component={NotFoundPage} />
			<Route path='/500' component={InternalServerErrorPage} />
			<Redirect to='/404' />
		</Switch>
	);
};

export default Routes;
