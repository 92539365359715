import { Button, Col, Divider, Result, Row } from 'antd';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { logout } from 'App/state/auth/auth.thunk';
import { getAccountDetails } from 'App/state/account/account.thunk';
import { RootState } from 'App/state/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EditProfileTabs } from './components/tabs/EditProfileTabs';
import './EditProfileContainer.less';

const EditProfileContainer: React.FC = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation(['page', 'common']);
	const history = useHistory();

	const accountDetails = useSelector((state: RootState) => state.account.accountDetails);
	const getAccountDetailsStatus = useSelector((state: RootState) => state.account.status.getAccountDetails);

	useEffect(() => {
		if (!accountDetails)
			dispatch(getAccountDetails());
	}, [dispatch, accountDetails]);


	const onLogoutClick = () => {
		let handleSuccess: () => void = () => {
			history.push('/');
		};
		dispatch(logout(handleSuccess));
	};

	const onChangePasswordClick = () => {
		history.push('/account/change-password');
	}


	if (accountDetails) {
		return (
			<>
				<Row justify='center'>

					<Col xs={24} sm={24} md={24} lg={24} xl={24} className="edit-profile-container-image-block">
						<Row justify='center' align='middle' style={{ height: '100%' }}>
							<span className="edit-profile-container-image-block-text">Moje konto</span>
						</Row>
					</Col>

					<Col xs={22} sm={22} md={20} lg={10} xl={10}>
						<Row justify='end'>

							<Col xs={24} sm={24} md={24} lg={24} xl={24}>

								{/* Okno z zakładkami */}
								<EditProfileTabs accountDetails={accountDetails} />

								<Divider />
							</Col>
						</Row>
						<Row justify='center'>
							<Col>

								<Button
									danger
									className='change-password-button'
									onClick={onChangePasswordClick}
								>
									Zmień hasło
							</Button>

								<Button
									danger
									className='logout-button'
									onClick={onLogoutClick}
								>
									Wyloguj
							</Button>

							</Col>
						</Row>
					</Col>
				</Row>

			</>
		);
	} else {
		if (getAccountDetailsStatus === StatusType.LOADING) {
			return <LoadingScreen container='fill' />;
		} else {
			return <Result
				status='warning'
				title={t('common:Errors.AnErrorOccured')}
				extra={
					<Button type='primary' onClick={() => history.push('/')}>
						{t('common:Actions.BackToHome')}
					</Button>
				}
			/>
		}
	}
};

export default EditProfileContainer;
