import React, { useRef, useState } from 'react';
import { Button, Col, Row, Carousel, Card, Rate, Modal } from 'antd';
import './HomePageContainer.less';

import tmpImage1 from './assets/about-us-1.jpg';
import tmpImage2 from './assets/about-us-2.jpg';
import tmpImage3 from './assets/about-us-3.jpg';

import menuCardImage1 from './assets/menu-card-1.jpg';
import menuCardImage2 from './assets/menu-card-2.jpg';
import menuCardImage3 from './assets/menu-card-3.jpg';

import menuDishImage from './assets/menu-dish.png';

import mobileAppImage from './assets/mobile-app-image.png';
import mobileAppAppleStoreImage from './assets/mobile-app-apple-store-image.png';
import mobileAppGooglePlayImage from './assets/mobile-app-google-play-image.png';

import appLogoBigImage from './assets/appLogoBig.png';
import facebookLogoImage from './assets/facebookLogo.png';
import messengerLogoImage from './assets/messengerLogo.png';
import erestoLogoImage from './assets/erestoLogoImage.png';

import { DownCircleOutlined } from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import Link from 'antd/lib/typography/Link';
import { useHistory } from 'react-router';

const HomePageContainer: React.FC<{}> = () => {
	const history = useHistory();

	let menuPageRef = useRef(null);
	const handleMenuScrollOnClick = () => menuPageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });

	let reviewsPageRef = useRef(null);
	const handleReviewsScrollOnClick = () =>
		reviewsPageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });

	let contactPageRef = useRef(null);
	const handleContactScrollOnClick = () =>
		contactPageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });

	let aboutUsPageRef = useRef(null);
	const handleAboutUsScrollOnClick = () =>
		aboutUsPageRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

	const showMenuRestaurantHandler = () => {
		history.push(`/restaurant-menu`);
	};

	const privacyPolicyHandler = () => {
		history.push(`/privacy-policy`);
	};

	const termsAndConditionsHandler = () => {
		history.push(`/terms-and-conditions`);
	};

	const signInHandler = () => {
		history.push(`/sign-in`);
	};

	const signUpHandler = () => {
		history.push(`/sign-up`);
	};

	const forgotPasswordHandler = () => {
		history.push(`/account/forgot-password`);
	};

	const promotionsHandler = () => {
		history.push(`/promotions`);
	};

	const accountHandler = () => {
		history.push(`/account`);
	};

	return (
		<div>
			{/* Ekran startowy */}
			<Row justify='start'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} className='home-container-landing-image-block'>
					<Row justify='center'>
						<Col xs={22} sm={20} md={20} lg={16} xl={16}>
							<div className='home-container-landing-text-block'>
								<img alt='' src={appLogoBigImage}></img>
								<div className='home-container-landing-divider'></div>

								<div className='home-container-landing-text-header'>
									Restauracja koreańsko - japońska
								</div>

								<div className='home-container-landing-button-block'>
									<Button
										onClick={showMenuRestaurantHandler}
										className='home-container-landing-button'
									>
										Zamów online
									</Button>
								</div>
							</div>
						</Col>
					</Row>

					<Row justify='center'>
						<DownCircleOutlined
							onClick={handleAboutUsScrollOnClick}
							className='home-container-landing-down-button'
						/>
					</Row>
				</Col>
			</Row>

			{/* Sekcja o nas */}
			<Row justify='center' ref={aboutUsPageRef}>
				<Col xs={22} sm={20} md={22} lg={18} xl={18} ref={aboutUsPageRef}>
					<div className='home-container-about-us-block'>
						<Row>
							<Col xs={24} sm={24} md={24} lg={8} xl={8}>
								<Carousel autoplay>
									<div>
										<img
											src={tmpImage1}
											alt='About us 1'
											className='home-container-about-us-cover-image'
										/>
									</div>
									<div>
										<img
											src={tmpImage2}
											alt='About us 2'
											className='home-container-about-us-cover-image'
										/>
									</div>
									<div>
										<img
											src={tmpImage3}
											alt='About us 3'
											className='home-container-about-us-cover-image'
										/>
									</div>
								</Carousel>
							</Col>
							<Col xs={24} sm={24} md={24} lg={16} xl={16}>
								<div className='home-container-about-us-text-block'>
									<div className='home-container-about-us-text-header'>Poznaj naszą restaurację</div>
									<div className='home-container-about-us-text-description'>
										Kultura jedzenia w restauracjach koreańskich stawia na dyskrecję, intymność i
										odseparowanie od reszty sali by móc delektować się posiłkiem. Dlatego też
										wykorzystujemy parawany przy każdym ze stolików. Dla ułatwienia kontaktu gości z
										kelnerkami, przy każdym odgrodzonym stole, jest zainstalowany przycisk wzywający
										obsługę sali. Jest to rozwiązanie bardzo częste w Korei, ale u nas praktycznie
										niespotykane.
									</div>
									<Row justify='end'>
										<Button
											onClick={showMenuRestaurantHandler}
											className='home-container-about-us-button'
											size={'large'}
										>
											Sprawdź nasze menu
										</Button>
									</Row>
								</div>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>

			{/* Sekcja nasze menu */}
			<Row justify='start' ref={menuPageRef}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} className='home-container-menu-image-block'>
					<Row>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<div>
								<Row justify='center'>
									<img
										src={menuDishImage}
										alt='Menu dish'
										style={{ height: '95px', marginTop: '100px' }}
									/>
								</Row>

								<Row justify='center'>
									<div className='home-container-menu-header'>Sprawdź nasze menu</div>
								</Row>
							</div>
						</Col>
					</Row>

					<Row justify='center'>
						<Col xs={24} sm={7} md={7} lg={5} xl={5}>
							<div className='home-container-menu-card-block'>
								<Card
									className='home-container-menu-card'
									hoverable
									style={{ width: '100%' }}
									cover={<img src={menuCardImage1} alt='Menu Card 1' />}
								>
									<Row justify='center'>
										<Meta title='Przystawki' />
									</Row>
									<Row justify='center'>
										<Button
											onClick={showMenuRestaurantHandler}
											className='home-container-menu-card-button'
											size={'small'}
										>
											Więcej
										</Button>
									</Row>
								</Card>
							</div>
						</Col>

						<Col xs={24} sm={7} md={7} lg={5} xl={5}>
							<div className='home-container-menu-card-block'>
								<Card
									className='home-container-menu-card'
									hoverable
									style={{ width: '100%' }}
									cover={<img src={menuCardImage2} alt='Menu Card 2' />}
								>
									<Row justify='center'>
										<Meta title='Dania główne' />
									</Row>
									<Row justify='center'>
										<Button
											onClick={showMenuRestaurantHandler}
											className='home-container-menu-card-button'
											size={'small'}
										>
											Więcej
										</Button>
									</Row>
								</Card>
							</div>
						</Col>

						<Col xs={24} sm={7} md={7} lg={5} xl={5}>
							<div className='home-container-menu-card-block'>
								<Card
									className='home-container-menu-card'
									hoverable
									style={{ width: '100%' }}
									cover={<img src={menuCardImage3} alt='Menu Card 3' />}
								>
									<Row justify='center'>
										<Meta title='Dodatki' />
									</Row>
									<Row justify='center'>
										<Button
											onClick={showMenuRestaurantHandler}
											className='home-container-menu-card-button'
											size={'small'}
										>
											Więcej
										</Button>
									</Row>
								</Card>
							</div>
						</Col>
					</Row>

					<Row justify='center'>
						<span className='home-container-menu-promotions' onClick={promotionsHandler}>
							Sprawdź aktualne promocje
						</span>
					</Row>
				</Col>
			</Row>

			{/* Sekcja informacje o resauracji */}
			<Row justify='center' ref={contactPageRef}>
				<Col xs={22} sm={18} md={14} lg={18} xl={18}>
					<Row justify='center'>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Row justify='center' className='home-container-photo-gallery-block-header'>
								Kontakt
							</Row>
							<Row justify='center'>
								<div className='home-container-photo-gallery-block-divider'></div>
							</Row>
						</Col>
					</Row>

					<div className='home-container-restaurant-info-block'>
						<Row>
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={8}
								xl={8}
								className='home-container-restaurant-info-column-1'
							>
								<Row className='home-container-restaurant-info-header' justify='center'>
									Kontakt
								</Row>
								<Row className='home-container-restaurant-info' justify='center'>
									goodmorningwroclaw@gmail.com
								</Row>
								<Row className='home-container-restaurant-info' justify='center'>
									+48 514 734 702
								</Row>
							</Col>

							<Col xs={24} sm={24} md={24} lg={8} xl={8}>
								<Row className='home-container-restaurant-info-header' justify='center'>
									Gdzie jesteśmy
								</Row>
								<Row className='home-container-restaurant-info' justify='center'>
									ul. Krzycka 1B
								</Row>
								<Row className='home-container-restaurant-info' justify='center'>
									53-019 Wrocław
								</Row>
							</Col>

							<Col xs={24} sm={24} md={24} lg={8} xl={8}>
								<Row className='home-container-restaurant-opening-hours-header' justify='center'>
									Godziny otwarcia
								</Row>
								<Row className='home-container-restaurant-opening-hours' justify='center'>
									Codziennie: 12:00 - 22:00
								</Row>
							</Col>
						</Row>

						<Row justify='center' align='middle'>
							<Button
								onClick={showMenuRestaurantHandler}
								className='home-container-restaurant-select-restaurant-button'
								size={'small'}
							>
								Zamów online
							</Button>
						</Row>
					</div>
				</Col>
			</Row>

			{/* Sekcja z galerią zdjęć */}
			<Row justify='center'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div className='home-container-photo-gallery-block'>
						<Row justify='center'>
							<Col
								xs={24}
								sm={12}
								md={8}
								lg={8}
								xl={8}
								className='home-container-photo-gallery-image-col-1'
							></Col>

							<Col
								xs={0}
								sm={12}
								md={8}
								lg={8}
								xl={8}
								className='home-container-photo-gallery-image-col-2'
							></Col>

							<Col
								xs={0}
								sm={0}
								md={8}
								lg={8}
								xl={8}
								className='home-container-photo-gallery-image-col-3'
							></Col>
						</Row>
					</div>
				</Col>
			</Row>

			{/* Sekcja z opiniami */}
			<Row justify='center' ref={reviewsPageRef}>
				<Col xs={22} sm={22} md={22} lg={20} xl={20}>
					<div className='home-container-reviews-block'>
						<Row justify='center'>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Row justify='center' className='home-container-reviews-block-header'>
									Opinie
								</Row>
								<Row justify='center' className='home-container-reviews-block-description'>
									Co mówią o nas klienci
								</Row>
								<Row justify='center'>
									<div className='home-container-reviews-block-divider'></div>
								</Row>
							</Col>
						</Row>

						<Row justify='center'>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Carousel autoplay dots={true}>
									<div>
										<Row justify='center' className='home-container-reviews-card-row'>
											<Col xs={22} sm={22} md={22} lg={6} xl={6}>
												<div className='home-container-reviews-card-block'>
													{/* Opinia */}
													<Row className='home-container-reviews-card-review'>
														Przepysznie! Bardzo miła obsługa i przepiękne parkowe otoczenie.
														Polecam!{' '}
													</Row>
													{/* Linia oddzielająca */}
													<Row>
														<div className='home-container-reviews-card-divider'></div>
													</Row>

													{/* Osoba dodająca opinie i ocena */}
													<Row justify='start'>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Row justify='start'>Krzysztof</Row>
														</Col>
														<Col xs={16} sm={16} md={16} lg={16} xl={16}>
															<Row justify='end'>
																<Rate
																	disabled
																	defaultValue={5}
																	className='home-container-reviews-rate'
																/>
															</Row>
														</Col>
													</Row>
												</div>
											</Col>
											<Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
											<Col xs={22} sm={22} md={22} lg={6} xl={6}>
												<div className='home-container-reviews-card-block'>
													{/* Opinia */}
													<Row className='home-container-reviews-card-review'>
														Jedne z najlepszych sushi, jakie kiedykolwiek jadłam. Porządne
														porcje i produkty wysokiego gatunku.
													</Row>
													{/* Linia oddzielająca */}
													<Row>
														<div className='home-container-reviews-card-divider'></div>
													</Row>
													{/* Osoba dodająca opinie i ocena */}
													<Row justify='start'>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Row justify='start'>Yullia</Row>
														</Col>
														<Col xs={16} sm={16} md={16} lg={16} xl={16}>
															<Row justify='end'>
																<Rate
																	disabled
																	defaultValue={5}
																	className='home-container-reviews-rate'
																/>
															</Row>
														</Col>
													</Row>
												</div>
											</Col>
											<Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
											<Col xs={22} sm={22} md={22} lg={6} xl={6}>
												<div className='home-container-reviews-card-block'>
													{/* Opinia */}
													<Row className='home-container-reviews-card-review'>
														Zdecydowanie moja ulubiona restauracja. Zawsze świeże produkty,
														szeroki wybór potraw.
													</Row>
													{/* Linia oddzielająca */}
													<Row>
														<div className='home-container-reviews-card-divider'></div>
													</Row>
													{/* Osoba dodająca opinie i ocena */}
													<Row justify='start'>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Row justify='start'>Anna</Row>
														</Col>
														<Col xs={16} sm={16} md={16} lg={16} xl={16}>
															<Row justify='end'>
																<Rate
																	disabled
																	defaultValue={5}
																	className='home-container-reviews-rate'
																/>
															</Row>
														</Col>
													</Row>
												</div>
											</Col>
										</Row>
									</div>
								</Carousel>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>

			{/* Sekcja z reklamą */}
			<Row justify='center'>
				<Col xs={22} sm={22} md={22} lg={18} xl={18}>
					<div className='home-container-ad-block'>
						<Row justify='center' align='middle' style={{ height: '100%' }}>
							<Col xs={24} sm={24} md={24} lg={18} xl={18} className='home-container-ad-block-card'>
								<Row justify='center'>
									<div className='home-container-ad-block-title'>
										Stwórz konto i korzystaj z promocji już dziś
									</div>
								</Row>

								<Row justify='center'>
									<div>
										<Button
											onClick={signUpHandler}
											className='home-container-ad-block-button'
											size={'small'}
										>
											Dodaj konto
										</Button>
									</div>
								</Row>

								<Row justify='center'>
									<div onClick={signInHandler} className='home-container-ad-block-footer'>
										Mam już konto
									</div>
								</Row>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>

			{/* Sekcja z podsumowaniem */}
			<Row justify='center' className='home-container-summary-row'>
				<Col xs={22} sm={22} md={22} lg={18} xl={18}>
					<Row align='middle' justify='center'>
						<div className='home-container-summary-block'>
							<Row justify='center'>
								<Col
									xs={20}
									sm={20}
									md={20}
									lg={11}
									xl={11}
									className='home-container-summary-general-col'
								>
									<span className='home-container-summary-general'>Co nas wyróżnia?</span>
								</Col>

								<Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>

								<Col
									xs={20}
									sm={20}
									md={20}
									lg={11}
									xl={11}
									className='home-container-summary-element-col'
								>
									<Row>
										<Col
											xs={4}
											sm={4}
											md={4}
											lg={4}
											xl={4}
											className='home-container-summary-number'
										>
											01.
										</Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-header'
										>
											<span>Menu</span>
										</Col>
									</Row>
									<Row>
										<Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-description'
										>
											Bogate menu kuchni koreańskiej i japońskiej.
										</Col>
									</Row>
								</Col>
							</Row>

							<Row justify='center'>
								<Col
									xs={20}
									sm={20}
									md={20}
									lg={11}
									xl={11}
									className='home-container-summary-element-col'
								>
									<Row>
										<Col
											xs={4}
											sm={4}
											md={4}
											lg={4}
											xl={4}
											className='home-container-summary-number'
										>
											02.
										</Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-header'
										>
											Wygoda w zamawianiu
										</Col>
									</Row>
									<Row>
										<Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-description'
										>
											Zamów swoje ulubione jedzenie w wygodny sposób za pomocą strony internetowej
											lub aplikacji mobilnej
										</Col>
									</Row>
								</Col>

								<Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>

								<Col
									xs={20}
									sm={20}
									md={20}
									lg={11}
									xl={11}
									className='home-container-summary-element-col'
								>
									<Row>
										<Col
											xs={4}
											sm={4}
											md={4}
											lg={4}
											xl={4}
											className='home-container-summary-number'
										>
											03.
										</Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-header'
										>
											Promocje
										</Col>
									</Row>
									<Row>
										<Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-description'
										>
											Przy zamówieniach na wynos z odbiorem osobistym otrzymacie 10% rabatu na
											wszystkie dania z karty.{' '}
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</Row>
				</Col>
			</Row>

			{/* Sekcja z aplikacją mobilną */}
			<Row justify='center'>
				<Col xs={22} sm={22} md={22} lg={18} xl={18}>

						<Row justify='center'>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Row justify='center' className='home-container-mobile-app-block-header'>
									Aplikacja mobilna
								</Row>
								<Row justify='center' className='home-container-mobile-app-block-description'>
									Zawsze pod ręką
								</Row>
								<Row justify='center'>
									<div className='home-container-mobile-app-block-divider'></div>
								</Row>
							</Col>
						</Row>

					<div className='home-container-mobile-app'>

						<Row justify='center' align='middle'>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
								<Row justify='center'>
									<img
										src={mobileAppImage}
										alt='Mobile app'
										className='home-container-mobile-app-image'
									/>
								</Row>
							</Col>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>

								<Row justify='center'>
									<a href="#" target="_blank" className='image-container-mobile-app-anchor'>
										<img
											src={mobileAppAppleStoreImage}
											alt='Mobile app'
											className='home-container-mobile-app-apple-store-image'
										/>
									</a>
								</Row>

								<Row justify='center'>
									<a href="#" target="_blank" className='image-container-mobile-app-anchor'>
										<img
											src={mobileAppGooglePlayImage}
											alt='Mobile app'
											className='home-container-mobile-app-google-play-image'
										/>
									</a>
								</Row>
							</Col>
						</Row>	
					</div>
				</Col>
			</Row>

			{/* Stopka */}
			<Row justify='center'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div className='home-container-footer-block'>
						<Row>
							<Col xs={24} sm={24} md={24} lg={7} xl={7}>
								<Row justify='center' align='top' className='home-container-footer-image-row'>
									<img src={appLogoBigImage} className='home-container-footer-image' alt='App logo' />
								</Row>
							</Col>

							<Col xs={24} sm={24} md={24} lg={4} xl={4}>
								<div className='home-container-footer-menu-block'>
									<div className='home-container-footer-menu-divider'></div>
									<ul>
										<li>
											<Link href='/'>Strona główna</Link>
										</li>
										<li>
											<Link onClick={handleAboutUsScrollOnClick}>O nas</Link>
										</li>
										<li>
											<Link onClick={handleMenuScrollOnClick}>Menu</Link>
										</li>
										<li>
											<Link onClick={handleReviewsScrollOnClick}>Opinie</Link>
										</li>
										<li>
											<Link onClick={handleContactScrollOnClick}>Kontakt</Link>
										</li>
									</ul>
								</div>
							</Col>

							<Col xs={24} sm={24} md={24} lg={6} xl={6}>
								<div className='home-container-footer-menu-block'>
									<div className='home-container-footer-menu-divider'></div>
									<ul>
										<li>
											<Link onClick={signInHandler}>Zaloguj się</Link>
										</li>
										<li>
											<Link onClick={signUpHandler}>Stwórz konto</Link>
										</li>
										<li>
											<Link onClick={forgotPasswordHandler}>Zapomniałem hasła</Link>
										</li>
										<li>
											<Link onClick={termsAndConditionsHandler}>Regulamin</Link>
										</li>
										<li>
											<Link onClick={privacyPolicyHandler}>
												Polityka prywatności
											</Link>
										</li>
									</ul>
								</div>
							</Col>

							<Col xs={24} sm={24} md={24} lg={6} xl={6}>
								<div className='home-container-footer-button-block'>
									<Row className='home-container-footer-button-block-section-1'>
										<Button
											onClick={showMenuRestaurantHandler}
											className='home-container-footer-button'
											size={'small'}
										>
											Zamów online
										</Button>
										<Button
											onClick={accountHandler}
											className='home-container-footer-button'
											size={'small'}
										>
											Moje konto
										</Button>
									</Row>
									<Row className='home-container-footer-button-block-section-2'>
										<span>Jesteśmy na</span>
									</Row>
									<Row className='home-container-footer-button-block-section-3'>
										<a
											href='https://www.facebook.com/restauracjanaru/'
											target='_blank'
											rel='noopener noreferrer'
										>
											<img
												alt=''
												src={facebookLogoImage}
												style={{ objectFit: 'cover', width: '20%' }}
											/>
											<img
												src={messengerLogoImage}
												style={{ objectFit: 'cover', width: '20%' }}
												alt='Messenger logo'
											/>
										</a>
									</Row>
								</div>
							</Col>
						</Row>


						<Row justify='center'>
							<div className='home-container-footer-eresto-block'>

								<a href='https://eresto.pl/' target='_blank' className='home-container-footer-eresto-logo-anchor'>
										<img
											className='home-container-footer-eresto-logo'
											src={erestoLogoImage}
											alt='Eresto logo'
										/>
										<div className='home-container-footer-eresto-logo-link'> eresto.pl</div>
								</a>
							</div>
						</Row>

					</div>
				</Col>
			</Row>


		</div>
	);
};

export default HomePageContainer;
