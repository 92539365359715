import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, PageHeader, Result, Row, notification } from 'antd';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { RootState } from 'App/state/root.reducer';
import { StatusType } from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import EditProfileForm from './components/editProfileForm/EditProfileForm';
import { getAccountDetails, updateAccountDetails, updateAccountPhoneNumber } from 'App/state/account/account.thunk';
import { UpdateAccountDetailsRequest } from 'App/api/endpoints/account/requests';
import './EditProfileFormContainer.less';

const { LOADING } = StatusType;

const EditProfileFormContainer: React.FC<{}> = () => {

	const history = useHistory();
	const dispatch = useDispatch();

	const { t } = useTranslation(['page', 'common']);

	const accountDetails = useSelector((state: RootState) => state.account.accountDetails);
	const updateAccountStatus = useSelector((state: RootState) => state.account.status.updateAccountDetails);

	useEffect(() => {
		if (!accountDetails)
			dispatch(getAccountDetails());
	}, [dispatch, accountDetails]);

	const accountStatus = useSelector((state: RootState) => state.account.status);


	const onFinish = (values: UpdateAccountDetailsRequest) => {
		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AccountPage.EditProfileContainer.UpdateAccountDetailsSuccess')
			});
		};

		const onUpdateAccountDetailsSuccess = () => {
			dispatch(updateAccountPhoneNumber({ new_phone_number: values.new_phone_number }, onSuccess))
			history.push('/account')

		}

		dispatch(updateAccountDetails(values, onUpdateAccountDetailsSuccess));
	};

	return accountStatus.updateAccountDetails === LOADING ? (
		<LoadingScreen container='screen' />
	) : accountDetails ? (
		<React.Fragment>
			<Col xs={24} sm={24} md={24} lg={24} xl={24} className="edit-profile-form-container-image-block">
				<Row justify='center' align='middle' style={{ height: '100%' }}>
					<span className="edit-profile-form-container-image-block-text">Edytuj profil</span>
				</Row>
			</Col>

			<Row align='middle' justify='center'>
				<Col span={24}>
					<Row justify='center'>
						<Col>
							<PageHeader title='Edycja profilu' />
						</Col>
					</Row>
					<EditProfileForm
						initialValues={{
							name: accountDetails.client_information.name,
							last_name: accountDetails.client_information.last_name,
							new_phone_number: accountDetails.client_information.phone_number,
						}}
						onFinish={onFinish}
						loading={updateAccountStatus === LOADING}
					/>
				</Col>
			</Row>
		</React.Fragment>
	) : (
		<Result
			status='404'
			title={t('common:Errors.AnErrorOccured')}
			subTitle={t('common:Errors.CompanyNotFound')}
			extra={
				<Button type='primary' onClick={() => history.push('/')}>
					{t('common:Actions.BackToHome')}
				</Button>
			}
		/>
	);
};

export default EditProfileFormContainer;