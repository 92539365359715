import { Button, Col, Empty, Result, Row, Typography } from 'antd';
import { OrderForGetUserOrdersResponse } from 'App/api/endpoints/userOrders/responses/getUserOrdersResponse';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { cleanUpOrdersState } from 'App/state/orders/orders.slice';
import { getUserOrders } from 'App/state/orders/orders.thunk';
import { RootState } from 'App/state/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import OrderListCard from './components/OrderListCard';
import './OrdersList.less';

const { SUCCESS, FAILED, LOADING, INITIAL } = StatusType;

const OrdersList = () => {
	const dispatch = useDispatch();

	const orders = useSelector((state: RootState) => state.orders.orders);
	const ordersStatus = useSelector((state: RootState) => state.orders.status.getUserOrders);

	const ordersFetchIntervalInSeconds = 20;

	const [finishedOrders, setFinishedOrders] = useState<OrderForGetUserOrdersResponse[]>([]);
	const [unFinishedOrders, setUnFinishedOrders] = useState<OrderForGetUserOrdersResponse[]>([]);

	useEffect(() => {
		let timeoutId: any = '';
		if (ordersStatus !== LOADING && ordersStatus !== INITIAL) {
			timeoutId = setTimeout(() => {
				dispatch(getUserOrders());
			}, ordersFetchIntervalInSeconds * 1000);
		}

		if (ordersStatus === INITIAL) {
			dispatch(getUserOrders());
		}

		return () => {
			if (timeoutId !== '') {
				clearTimeout(timeoutId);
			}
		};
	}, [dispatch, ordersStatus]);

	useEffect(() => {
		return () => {
			clearTimeout();
			dispatch(cleanUpOrdersState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (orders.length > 0) {
			setFinishedOrders(orders.filter((o) => o.order_status.name === 'FINISHED'));
			setUnFinishedOrders(orders.filter((o) => o.order_status.name !== 'FINISHED'));
		}
	}, [orders]);

	if (ordersStatus === SUCCESS) {
		if (orders && orders.length > 0) {
			return (
				<>
					<Col xs={24} sm={24} md={24} lg={24} xl={24} className="orders-list-image-block">
						<Row justify='center' align='middle' style={{height: '100%'}}>
							<span className="orders-list-image-block-text">Moje zamówienia</span>
						</Row>
					</Col>
					{unFinishedOrders.length > 0 && (
						<Row justify='center' className="orders-list-top-row">
							<Col xs={24} lg={16} xl={12}>
								<Row>
									{unFinishedOrders.map((order) => (
										<Col key={order.id} xs={24} className='pt-2'>
											<Link to={`/orders/${order.id}`}>
												<OrderListCard order={order} />
											</Link>
										</Col>
									))}
								</Row>
							</Col>
						</Row>
					)}
					{finishedOrders.length > 0 && (
						<Row justify='center' className='pt-2'>
							<Col xs={24} lg={16} xl={12}>
								<Typography.Text type='secondary'>Zakończone zamówienia</Typography.Text>
								<Row>
									{finishedOrders.map((order) => (
										<Col key={order.id} xs={24} className='pt-2'>
											<Link to={`/orders/${order.id}`}>
												<OrderListCard order={order} />
											</Link>
										</Col>
									))}
								</Row>
							</Col>
						</Row>
					)}
				</>
			);
		} else {
			return (
				<Empty
					className='pt-5'
					description={'Brak zamówień'}
					image={require('../../../common/assets/empty-placeholder-bw.jpg')}
					imageStyle={{
						height: 60,
					}}
				/>
			);
		}
	} else if (ordersStatus === FAILED) {
		return (
			<Result
				status='error'
				title='Wystąpił problem'
				extra={
					<Link to='/'>
						<Button type='primary' key='console'>
							Strona główna
						</Button>
					</Link>
				}
			/>
		);
	} else {
		return <LoadingScreen container='screen' />;
	}
};

export default OrdersList;
