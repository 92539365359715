import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { localStorageHelper } from 'App/common/utils/localStorageHelper';
import { Cart } from 'App/domain/entities/cart';
import { Restaurant } from 'App/domain/entities/restaurant';
import { UserAddress } from 'App/domain/entities/userAddress';
import { DeliveryMethodEnum } from 'App/domain/enums/deliveryMethodEnum';
import { PaymentMethodEnum } from 'App/domain/enums/paymentMethodEnum';
import StatusType from 'App/types/requestStatus';
import { AnonymousClientData, newOrderInitialStateLocalStorage, NewOrderState } from './newOrder.state';

const { FAILED, LOADING, SUCCESS } = StatusType;

const newOrderSlice = createSlice({
	name: 'new-order',
	initialState: newOrderInitialStateLocalStorage,
	reducers: {
		updateCart: (state: NewOrderState, action: PayloadAction<Cart>) => {
			localStorage.setItem(localStorageHelper.keys.cart, JSON.stringify(action.payload));
			localStorage.setItem(localStorageHelper.keys.cartDate, '' + new Date());
			state.cart = action.payload;
		},
		updateRestaurant: (state: NewOrderState, action: PayloadAction<Restaurant>) => {
			localStorage.setItem(localStorageHelper.keys.restaurant, JSON.stringify(action.payload));
			localStorage.setItem(localStorageHelper.keys.restaurantDate, '' + new Date());
			state.restaurant = action.payload;
		},
		updateTableNumber: (state: NewOrderState, action: PayloadAction<number>) => {
			state.tableNumber = action.payload;
		},

		updateDeliveryMethodEnum: (state: NewOrderState, action: PayloadAction<DeliveryMethodEnum>) => {
			state.deliveryMethodEnum = action.payload;
		},

		updatePaymentMethodEnum: (state: NewOrderState, action: PayloadAction<PaymentMethodEnum>) => {
			state.paymentMethodEnum = action.payload;
		},

		updateUserAddress: (state: NewOrderState, action: PayloadAction<UserAddress>) => {
			state.userAddress = action.payload;
		},

		updateIsAddressOnDeliveryZones: (state: NewOrderState, action: PayloadAction<boolean>) => {
			state.isAddressOnDeliveryZones = action.payload;
		},

		changeDeliveryOrAdressStart: (state: NewOrderState) => {
			state.changingDeliveryOrAddress = true;
		},

		changeDeliveryOrAdressEnd: (state: NewOrderState) => {
			state.changingDeliveryOrAddress = false;
		},

		addNewOrderStart: (state: NewOrderState) => {
			state.status.addNewOrderStatus = LOADING;
		},
		addNewOrderSuccess: (state: NewOrderState) => {
			state.status.addNewOrderStatus = SUCCESS;
		},
		addNewOrderFailure: (state: NewOrderState) => {
			state.status.addNewOrderStatus = FAILED;
		},

		setUnloggedUserAddress: (state: NewOrderState, action: PayloadAction<UserAddress>) => {
			//console.log(action.payload);
			state.userAddress = action.payload;
		},

		setAnonymousClientData: (state: NewOrderState, action: PayloadAction<AnonymousClientData>) => {
			state.anonymousClientData = action.payload;
		},

		clearNewOrderState: (state: NewOrderState) => {
			//state = newOrderInitialState nie działa, są issues na github reduxtoolkit xD
			state.status = newOrderInitialStateLocalStorage.status;
			state.cart = null;
			state.restaurant = null;
			state.userAddress = null;
			state.paymentMethodEnum = PaymentMethodEnum.fast_transfer;
			state.deliveryMethodEnum = DeliveryMethodEnum.self_pickup;
			state.tableNumber = 1;
			state.isAddressOnDeliveryZones = false;
			state.changingDeliveryOrAddress = false;

			//NIE USUWAM state.newOrderResponse bo przydaje się potem
		},
	},
});

export default newOrderSlice;

export const {
	updateCart,
	updateRestaurant,
	updateTableNumber,
	updateDeliveryMethodEnum,
	updatePaymentMethodEnum,
	updateUserAddress,
	updateIsAddressOnDeliveryZones,

	changeDeliveryOrAdressStart,
	changeDeliveryOrAdressEnd,

	addNewOrderStart,
	addNewOrderSuccess,
	addNewOrderFailure,

	setUnloggedUserAddress,

	setAnonymousClientData,

	clearNewOrderState,
} = newOrderSlice.actions;
