import { Form, Input } from 'antd';
import { setAnonymousClientData } from 'App/state/newOrder/newOrder.slice';
import { AnonymousClientData } from 'App/state/newOrder/newOrder.state';
import { RootState } from 'App/state/root.reducer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { anonymousUserOrderFormRules } from './utils/anonymousUserOrderFormRules';

interface AnonymousUserOrderFormProps {}

const AnonymousUserOrderForm = (props: AnonymousUserOrderFormProps) => {
	const dispatch = useDispatch();
	const anonymousClientData = useSelector((state: RootState) => state.newOrder.anonymousClientData);

	const layout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};

	const handleFormValuesChange = (changedValues, values) => {
		//console.log(values);
		let anonymousClientData = {} as AnonymousClientData;
		let formValid = true;
		values.forEach((element) => {
			if (element.touched === true && element.errors.length <= 0 && element.validating === false) {
				const fieldName = element.name[0];
				anonymousClientData[fieldName] = element.value;
			} else {
				formValid = false;
			}
		});

		if (formValid) {
			// ustaw obiekt w stejcie
			dispatch(setAnonymousClientData(anonymousClientData));
		} else {
			// zresetuj obiekt w stejcie
			dispatch(setAnonymousClientData(null));
		}
	};

	return (
		<Form {...layout} onFieldsChange={handleFormValuesChange} initialValues={anonymousClientData}>
			<Form.Item
				name='name'
				label='Imię'
				rules={anonymousUserOrderFormRules.name()}
				messageVariables={{ arg: 'Imię' }}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name='last_name'
				label='Nazwisko'
				rules={anonymousUserOrderFormRules.last_name()}
				messageVariables={{ arg: 'Nazwisko' }}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name='phone_number'
				label='Numer telefonu'
				rules={anonymousUserOrderFormRules.phone_number()}
				messageVariables={{ arg: 'Numer telefonu' }}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name='email'
				label='Email'
				rules={anonymousUserOrderFormRules.email()}
				messageVariables={{ arg: 'Email' }}
			>
				<Input />
			</Form.Item>
		</Form>
	);
};

export default AnonymousUserOrderForm;
