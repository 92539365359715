import { Rule } from 'antd/lib/form';
import i18n from 'i18n';

export const registerFormRules = {
	email: (): Rule[] => [{ required: true, type: 'email', max: 255 }],
	password: (): Rule[] => [
		{
			pattern: RegExp('\\d'),
			message: i18n.t('form:ValidationMessages.Custom.MustContainNumber', {
				arg: i18n.t('form:Common.User.Labels.Password'),
			}),
		},
		{
			pattern: RegExp('[*@!#%&()^~{}]+'),
			message: i18n.t('form:ValidationMessages.Custom.MustContainSpecial', {
				arg: i18n.t('form:Common.User.Labels.Password'),
			}),
		},
		{
			pattern: RegExp('(?=.*[a-z])'),
			message: i18n.t('form:ValidationMessages.Custom.MustContainLowercase', {
				arg: i18n.t('form:Common.User.Labels.Password'),
			}),
		},
		{
			pattern: RegExp('(?=.*[A-Z])'),
			message: i18n.t('form:ValidationMessages.Custom.MustContainUppercase', {
				arg: i18n.t('form:Common.User.Labels.Password'),
			}),
		},
		{
			min: 6,
		},
		{
			required: true,
			max: 100,
		},
	],

	confirmPassword: (): Rule[] => [
		{
			required: true,
		},
		({ getFieldValue }) => ({
			validator(rule, value) {
				if (!value || getFieldValue('password') === value) {
					return Promise.resolve();
				}

				const arg = [
					i18n.t('form:Common.User.Labels.Password'),
					i18n.t('form:Common.User.Labels.ConfirmPassword'),
				];
				return Promise.reject(i18n.t('form:ValidationMessages.Custom.PasswordMismatch', { arg }));
			},
		}),
	],

	agreement: (): Rule[] => [
		{
			validator: (_, value) =>
				value
					? Promise.resolve()
					: Promise.reject(new Error('Musisz najpierw zaakceptować regulamin i politykę prywatności')),
		},
	],
};
