import { Col, notification, PageHeader, Row } from 'antd';
import { generateNewPassword } from 'App/state/account/account.thunk';
import { RootState } from 'App/state/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { GenerateNewPasswordRequest } from 'App/api/endpoints/account/requests';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { cleanUpAccountStatusStart } from 'App/state/account/account.slice';
import './ForgotPasswordContainer.less';

interface ForgotPasswordContainerProps extends RouteComponentProps {}

const ForgotPasswordContainer: React.FC<ForgotPasswordContainerProps> = ({ history }: ForgotPasswordContainerProps) => {
	type FinishFormType = (values: Store) => void;

	const dispatch = useDispatch();
	const status = useSelector((state: RootState) => state.account.status.forgotPassword);
	const { t } = useTranslation('page');

	useEffect(() => {
		return () => dispatch(cleanUpAccountStatusStart());
	}, [dispatch]);

	const forgotPasswordHandler: FinishFormType = (values: GenerateNewPasswordRequest) => {
		let handleSuccess: () => void = () => {
			history.push('/');
			notification.success({
				message: t('common:Success.Success'),
				description: t('AccountPage.ForgotPasswordContainer.ForgotPasswordEmailSuccess'),
			});
		};

		dispatch(generateNewPassword(values, handleSuccess));
	};

	return (
		<div className='forgotPasswordContainer'>
			<Col xs={24} sm={24} md={24} lg={24} xl={24} className="forgot-password-container-image-block">
				<Row justify='center' align='middle' style={{height: '100%'}}>
					<span className="forgot-password-container-image-block-text">Zapomniałem hasła</span>
				</Row>
			</Col>
			<Row align='middle' justify='center'>
				<Col xs={22} md={14} xl={10} xxl={8}>
					<br />
					<PageHeader title={t('AccountPage.ForgotPasswordContainer.PageHeaderTitle')} />
					<ForgotPasswordForm
						className='forgotPassword-form'
						name='forgotPasswordForm'
						size='large'
						onFinish={forgotPasswordHandler}
						autoComplete='off'
						loading={status === StatusType.LOADING}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default ForgotPasswordContainer;
