import { Input, Button, Form } from 'antd';
import { FormProps } from 'antd/lib/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { addUserAddressFormRules } from '../../utils/addUserAddressFormRules';

interface UpdateUserAddressFormProps extends FormProps {
	initialValues: {
		name: string;
		address_1: string;
		address_2: string;
		city: string;
		comment: string;
	};
	loading: boolean;
}

const UpdateUserAddressForm: React.FC<UpdateUserAddressFormProps> = (props: UpdateUserAddressFormProps) => {
	const { t } = useTranslation(['form', 'common']);

	const layout = {
		labelCol: {
			xs: {
				span: 24
			},
			md: {
				span: 8
			}
		},
		wrapperCol: {
			xs: {
				span: 24
			},
			md: {
				span: 8
			}
		}
	};

	const tailLayout = {
		wrapperCol: {
			xs: {
				span: 24
			},
			md: {
				span: 8,
				offset: 8
			}
		}
	};

	const { loading, ...rest } = props;

	return (
		<Form className='px-3' {...layout} {...rest}>

			{/* Nazwa */}
			<Form.Item
				name='name'
				label={t('form:Common.User.Labels.AddressName')}
				rules={addUserAddressFormRules.name()}
				messageVariables={{ arg: t('form:Common.User.Labels.AddressName') }}
			>
				<Input />
			</Form.Item>

			{/* Adres 1 */}
			<Form.Item
				name='address_1'
				label={t('form:Common.User.Labels.Address1')}
				rules={addUserAddressFormRules.address_1()}
				messageVariables={{ arg: t('form:Common.User.Labels.Address1') }}
			>
				<Input />
			</Form.Item>

			{/* Adres 2 */}
			<Form.Item
				name='address_2'
				label={t('form:Common.User.Labels.Address2')}
				rules={addUserAddressFormRules.address_2()}
				messageVariables={{ arg: t('form:Common.User.Labels.Address2') }}
			>
				<Input />
			</Form.Item>

			{/* Miasto */}
			<Form.Item
				name='city'
				label={t('form:Common.User.Labels.City')}
				rules={addUserAddressFormRules.city()}
				messageVariables={{ arg: t('form:Common.User.Labels.City') }}
			>
				<Input />
			</Form.Item>

			{/* Komentarz */}
			<Form.Item
				name='comment'
				label={t('form:Common.User.Labels.Comment')}
				rules={addUserAddressFormRules.comment()}
				messageVariables={{ arg: t('form:Common.User.Labels.Comment') }}
			>
				<Input />
			</Form.Item>

			<Form.Item {...tailLayout}>
				<Button block loading={loading} type='primary' htmlType='submit'>
					{t('common:Actions.Save')}
				</Button>
			</Form.Item>

		</Form>
	);
};

export default UpdateUserAddressForm;
