import React from 'react';

import { Route, Switch } from 'react-router';
import PromotionsList from './promotionsList/PromotionsList';

const PromotionsPageContainer: React.FC<{}> = () => {
	return (
		<Switch>
			<Route exact path='/promotions' component={PromotionsList} />
		</Switch>
	);
};

export default PromotionsPageContainer;
