import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';

export const getValidProductIds = (promotion: PromotionForGetAllUserPromotions) => {
	const productIds: number[] = [];

	for (const product of promotion.promotion_products) {
		productIds.push(product.product.id);
	}

	return productIds;
};
