import { Rule } from "antd/lib/form";
import i18n from 'i18n';

export const editProfileFormRules = {
    firstName: (): Rule[] => [{ required: true, max: 255 }],
    lastName: (): Rule[] => [{ required: true, max: 255 }],
    phoneNumber: (): Rule[] => [{
        required: true,
        pattern: RegExp('^(\\+\\s?)?((?<!\\+.*)\\(\\+?\\d+([\\s\\-\\.]?\\d+)?\\)|\\d+)([\\s\\-\\.]?(\\(\\d+([\\s\\-\\.]?\\d+)?\\)|\\d+))*(\\s?(x|ext\\.?)\\s?\\d+)?$', "im"),
        message: i18n.t('form:ValidationMessages.Custom.PhoneNumber', { arg: i18n.t('form:Common.User.Labels.PhoneNumber') })
    }]
};