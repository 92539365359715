import agent from 'App/api/agent/agent';
import { newOrderRequestMapper } from 'App/api/mappers/newOrderRequestMapper';
import { NewOrderRequest } from 'App/api/endpoints/userOrders/requests/newOrderRequest';
import { AppThunk } from '../store';
import { addNewOrderStart, addNewOrderSuccess, addNewOrderFailure, clearNewOrderState } from './newOrder.slice';
import { paymentURL, returnUrlAfterPayment } from 'App/api/agent/axios/configuration';
import { AnonymousClientData } from './newOrder.state';

export const addNewOrder = (payload: NewOrderRequest, onSuccess: () => void, history): AppThunk => async (dispatch) => {
	dispatch(addNewOrderStart());

	let mappedRequest = newOrderRequestMapper(payload);

	mappedRequest.url_to_return = returnUrlAfterPayment;

	agent.UserOrders.addOrder(mappedRequest)
		.then((response) => {
			if (response.isPaymentMethodInRestaurant) {
				//backend w "response.orderId" zwraca albo orderId albo anonymousOrderId
				history.push(`/orders/${response.orderId}`)
				dispatch(addNewOrderSuccess());
				dispatch(clearNewOrderState());
				onSuccess();
			}
			else {
				let token = response.p24response.token;
				onSuccess();
				window.location.href = paymentURL + token;
				dispatch(addNewOrderSuccess());
			}
		})
		.catch((error) => {
			dispatch(addNewOrderFailure());
		});
};

export const addNewAnonymousOrder = (
	payload: NewOrderRequest,
	anonymousClientData: AnonymousClientData,
	onSuccess: () => void,
	history,
): AppThunk => async (dispatch) => {
	dispatch(addNewOrderStart());

	let mappedRequest = newOrderRequestMapper(payload);

	mappedRequest.url_to_return = returnUrlAfterPayment;
	mappedRequest.anonymous_client_data = anonymousClientData;

	agent.UserOrders.addAnonymousOrder(mappedRequest)
		.then((response) => {
			if (response.isPaymentMethodInRestaurant) {
				//backend w "response.orderId" zwraca albo orderId albo anonymousOrderId
				history.push(`/orders/${response.orderId}`)
				dispatch(addNewOrderSuccess());
				onSuccess();
				dispatch(clearNewOrderState());
			}
			else {
				let token = response.p24response.token;
				onSuccess();
				window.location.href = paymentURL + token;
				dispatch(addNewOrderSuccess());
			}
		})
		.catch((error) => {
			dispatch(addNewOrderFailure());
		});
};
