import { DeliveryMethodEnum } from 'App/domain/enums/deliveryMethodEnum';
import { PaymentMethodEnum } from 'App/domain/enums/paymentMethodEnum';
import { cartService } from 'App/services/cartService';
import { NewOrderRequest } from '../endpoints/userOrders/requests/newOrderRequest';

export const newOrderRequestMapper = (request: NewOrderRequest): any => {
	const getPaymentMethod = (paymentMethodEnum) => {
		switch (paymentMethodEnum) {
			case PaymentMethodEnum.blik:
				return 'BLIK';
			case PaymentMethodEnum.fast_transfer:
				return 'Przelewy24';
			case PaymentMethodEnum.credit_card:
				return 'Visa/Mastercard';
			case PaymentMethodEnum.in_restaurant:
				return 'In restaurant';
			default:
				return 'Przelewy24';
		}
	};

	return {
		place: { id: request.restaurant.id },
		is_self_pickup: request.deliveryMethodEnum === DeliveryMethodEnum.self_pickup,
		is_order_to_table: request.deliveryMethodEnum === DeliveryMethodEnum.to_table,
		to_table_number: request.deliveryMethodEnum === DeliveryMethodEnum.to_table ? request.tableNumber : null,
		cart_price_in_pln: cartService.getCartPrice(request.cart),
		final_price_in_pln: cartService.getFinalCartPrice(request.cart),
		delivery_price_in_pln: request.cart.delivery_cost_in_pln,
		payment_method: getPaymentMethod(request.paymentMethodEnum),
		client_address:
			request.userAddress != null
				? {
						address_1: request.userAddress.address_1,
						address_2: request.userAddress.address_2,
						city: request.userAddress.city,
						comment: request.userAddress.comment,
				  }
				: null,
		promotions: request.cart.promotions.map((promotion) => {
			return { id: promotion.id };
		}),
		products: request.cart.cart_products.map((cartProduct) => {
			return {
				id: cartProduct.product.id,
				count: cartProduct.count,
				option_items: cartProduct.option_items.map((optionItem) => {
					return { id: optionItem.id };
				}),
			};
		}),
	};
};
