import { Result, Row } from 'antd'
import React from 'react'

const PaymentMaintenanceBreakPage = () => {
    return (
        <Row justify="center" align="middle">
            <Result
                title="Płatności - przerwa techniczna"
            />
        </Row>
    )
}

export default PaymentMaintenanceBreakPage
