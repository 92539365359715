import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';

import { PageHeader, Row, Col, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';

import LoginForm from './components/LoginForm';
import { LoginRequest } from 'App/api/endpoints/auth/requests';
import { cleanUpAuthStatus, login } from 'App/state/auth/auth.thunk';
import { RootState } from 'App/state/root.reducer';
import StatusType from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import './LoginPageContainer.less';

interface LoginPageContainerProps extends RouteChildrenProps {
	name?: string;
}

const LoginPageContainer: React.FC<LoginPageContainerProps> = ({ history }: LoginPageContainerProps) => {
	type FinishFormType = (values: Store) => void;

	const { t } = useTranslation('page');

	const dispatch = useDispatch();
	const status = useSelector((state: RootState) => state.auth.status.login);

	useEffect(() => {
		return () => dispatch(cleanUpAuthStatus());
	}, [dispatch]);

	const formInitialValues = {
		email: '',
		password: '',
	};

	const signInHandler: FinishFormType = (values: LoginRequest) => {
		const onSuccess = () => {
			let returnUrl = localStorage.getItem('returnUrl');
			localStorage.removeItem('returnUrl');
			let path = returnUrl ? returnUrl : '/';
			history.push(path);
		};
		const onFailure = () => {
			notification['error']({
				message: i18n.t('common:Errors.Error'),
				description: i18n.t(`detailedErrors:06.03.DescriptionFormatter`),
			});
		};

		dispatch(login(values, onSuccess, onFailure));
	};

	return (
		<div>
			{/* <Image className='app-logo' preview={false} src={appLogo} /> */}
			<Row>
				<Col xs={24} sm={24} md={24} lg={12} xl={12} className='login-container-image-block'></Col>

				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Row align='middle' justify='center' className='login-container-form-block'>
						<Col xs={20} md={14} xl={14} xxl={14}>
							<PageHeader className='p-3' title={t('LoginPage.LoginPageContainer.PageHeaderTitle')} />
							<LoginForm
								name='loginForm'
								size='large'
								onFinish={signInHandler}
								initialValues={formInitialValues}
								autoComplete='off'
								loading={status === StatusType.LOADING}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default LoginPageContainer;
