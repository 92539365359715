export const getLocalDate = (date: string) => {
	const dateObj = new Date(date);
	return dateObj.toLocaleDateString('pl-PL', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
};

export const getLocalDateWithTime = (date: string) => {
	date = date.substr(date.length - 1) === 'Z' ? date : date + 'Z'

	const dateObj = new Date(date);
	return dateObj.toLocaleDateString('pl-PL', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
};
