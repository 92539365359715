import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Col, PageHeader, Row, notification } from 'antd';
import { RootState } from 'App/state/root.reducer';
import { StatusType } from 'App/types/requestStatus';
import { getAccountDetails, updateUserAddress } from 'App/state/account/account.thunk';
import { UpdateUserAddressRequest } from 'App/api/endpoints/account/requests';
import UpdateUserAddressForm from './components/updateUserAddressForm/UpdateUserAddressForm';

const { LOADING } = StatusType;

interface RouteParams {
	userAddressId: string;
}

interface UpdateUserAddressContainerProps extends RouteComponentProps<RouteParams> { }

const UpdateUserAddressContainer: React.FC<UpdateUserAddressContainerProps> = ({ match }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userAddressId = match.params.userAddressId;

	const updateUserAddressStatus = useSelector((state: RootState) => state.account.status.updateUserAddress);
	const accountDetails = useSelector((state: RootState) => state.account.accountDetails);
	const selectedUserAddress = accountDetails.client_information.client_addresses.find((a) => a.id === Number(userAddressId));



	const onFinish = (values: UpdateUserAddressRequest) => {
		const onSuccess = () => {
			dispatch(getAccountDetails());
			history.push('/account')

			notification.success({
				message: 'Sukces',
				description: 'Adres został zaktualizowany'
			});
		};

		dispatch(updateUserAddress(Number(userAddressId), values, onSuccess));
	};

	return (
		<React.Fragment>

			<Row align='middle' justify='center'>
				<Col span={24}>
					<Row justify='center'>
						<Col>
							<PageHeader title='Edycja adresu' />
						</Col>
					</Row>
					<UpdateUserAddressForm
						onFinish={onFinish}
						initialValues={{
							name: selectedUserAddress.name,
							address_1: selectedUserAddress.address_1,
							address_2: selectedUserAddress.address_2,
							city: selectedUserAddress.city,
							comment: selectedUserAddress.comment
						}}
						loading={updateUserAddressStatus === LOADING}
					/>
				</Col>
			</Row>
		</React.Fragment>
	);

};

export default UpdateUserAddressContainer;