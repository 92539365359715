import { Badge, Card, Col, Image, Row, Typography } from 'antd';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import { getDiscountText } from 'App/pages/PromotionsPage/utils/getDiscountText';
import { getPromotionImgUrl } from 'App/pages/PromotionsPage/utils/getPromotionImgUrl';
import { getLocalDate } from 'App/pages/PromotionsPage/utils/getLocalDate';
import { getValidProductsText } from 'App/pages/PromotionsPage/utils/getValidProductsText';
import React from 'react';

import './styles/PromotionListCard.less';

interface PromotionListCardProps {
	promotion: PromotionForGetAllUserPromotions;
}

const PromotionListCard: React.FC<PromotionListCardProps> = (props) => {
	const { promotion } = props;

	return (
		<Badge.Ribbon text={getDiscountText(promotion)}>
			<Card
				className='promotion-list-card'
				cover={
					<Image
						className='promotion-list-card__cover-image'
						preview={false}
						alt='img'
						src={getPromotionImgUrl(promotion)}
					/>
				}
				actions={[
					<Row justify='center' align='middle'>
						<Col span={12}>
							<Row>
								<Col xs={24}>
									<Typography.Text strong>{getLocalDate(promotion.date_until)}</Typography.Text>
								</Col>
								<Col xs={24}>
									<Typography.Text type='secondary'>Ważny do</Typography.Text>
								</Col>
							</Row>
						</Col>
						<Col span={12}>
							<Row>
								<Col xs={24}>
									<Typography.Text strong>{getValidProductsText(promotion)}</Typography.Text>
								</Col>
								<Col xs={24}>
									<Typography.Text type='secondary'>Objęte produkty</Typography.Text>
								</Col>
							</Row>
						</Col>
					</Row>,
				]}
			>
				<Card.Meta title={promotion.title} description={promotion.description}></Card.Meta>
			</Card>
		</Badge.Ribbon>
	);
};

export default PromotionListCard;
