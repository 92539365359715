// react
import React, { useEffect } from 'react';
import { Router } from 'react-router';

// third-party
import { ConfigProvider, Layout, notification } from 'antd';
import { useTranslation } from 'react-i18next';

// local direct
import Routes from './routes';
import './common/styles/bootstrap-utilities.less';
import NavbarContainer from './common/containers/NavbarContainer';
import history from '../app.history';

// styles
import './App.less';
import './common/styles/bootstrap-utilities.less';

// languages
import { validationMessages } from './common/utils/i18nDefaultAntDesignFormMessages';
import { i18nToAntdLocaleMapper } from './common/utils/i18nToAntdLocaleMapper';
import { useDispatch } from 'react-redux';
import { getAccountDetails } from './state/account/account.thunk';
import { appStarted } from './state/auth/auth.slice';

const { Content, Header } = Layout;
const App: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (localStorage.getItem('refresh_token')) {
			const onFinished = () => dispatch(appStarted());
			dispatch(getAccountDetails(onFinished, onFinished));
		} else {
			dispatch(appStarted());
		}
	}, [dispatch]);

	notification.config({
		placement: 'bottomRight',
		bottom: 0,
		duration: 5,
	});

	return (
		<ConfigProvider locale={i18nToAntdLocaleMapper()} form={{ validateMessages: validationMessages(t) }}>
			<Router history={history}>
				<Layout className='layout'>
					<Header className='fixed-header bg-site'>
						<NavbarContainer />
					</Header>
					<Content className='content-layout' style={{ marginTop: '66px' }}>
						<Routes />
					</Content>
				</Layout>
			</Router>
		</ConfigProvider>
	);
};

export default App;
