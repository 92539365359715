import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';

import { PageHeader, Row, Col, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';

import RegisterForm from './components/RegisterForm';
import { RegisterRequest } from 'App/api/endpoints/auth/requests';
import { register } from 'App/state/auth/auth.thunk';
import { RootState } from 'App/state/root.reducer';
import StatusType from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import './RegisterPageContainer.less';

interface RegisterPageContainerProps extends RouteChildrenProps {
	name?: string;
}

const RegisterPageContainer: React.FC<RegisterPageContainerProps> = ({ history }: RegisterPageContainerProps) => {
	type FinishFormType = (values: Store) => void;

	const { t } = useTranslation('page');

	const dispatch = useDispatch();
	const status = useSelector((state: RootState) => state.auth.status.register);

	const registerHandler: FinishFormType = (values: RegisterRequest) => {
		let handleSuccess: () => void = () => {
			history.push('/sign-in');
			notification.success({
				message: t('common:Success.Success'),
				description: 'Rejestracja przebiegła pomyślnie. Potwierdź konto poprzez link wysłany na e-maila',
			});
		};
		dispatch(register(values, handleSuccess));
	};

	return (
		<div>
			<Row>
				<Col xs={24} sm={24} md={24} lg={12} xl={12} className='register-container-image-block'></Col>

				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Row align='middle' justify='center' className='register-container-form-block'>
						<Col xs={20} md={14} xl={14} xxl={14}>
							<PageHeader title={t('RegisterPage.RegisterPageContainer.PageHeaderTitle')} />
							<RegisterForm
								preserve
								className='register-form'
								name='registerForm'
								size='large'
								onFinish={registerHandler}
								autoComplete='off'
								loading={status === StatusType.LOADING}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default RegisterPageContainer;
