import { CarOutlined, HomeOutlined, LikeOutlined, SettingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Card, Row, Col, Typography, Button, Result, Steps, Divider } from 'antd';
import { orderStatusMapper } from 'App/api/mappers/orderStatusMapper';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { OrderStatusEnum } from 'App/domain/enums/orderStatusEnum';
import { getLocalDateWithTime } from 'App/pages/PromotionsPage/utils/getLocalDate';
import { cleanUpOrdersState } from 'App/state/orders/orders.slice';
import { getAnonymousUserOrder, getUserOrder } from 'App/state/orders/orders.thunk';
import { RootState } from 'App/state/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import './OrderDetails.less';

interface RouteParams {
	orderId: string;
}

interface OrderDetailsProps extends RouteComponentProps<RouteParams> {}

const { FAILED, LOADING, SUCCESS, INITIAL } = StatusType;

const OrderDetails: React.FC<OrderDetailsProps> = (props) => {
	const { orderId } = props.match.params;
	const ordersFetchIntervalInSeconds = 20;

	const dispatch = useDispatch();

	const selectedOrder = useSelector((state: RootState) => state.orders.selectedOrder);
	const selectedOrderStatus = useSelector((state: RootState) => state.orders.status.getUserOrder);
	const accountDetails = useSelector((state: RootState) => state.account.accountDetails);

	useEffect(() => {
		return () => {
			clearTimeout();
			dispatch(cleanUpOrdersState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let timeoutId: any = '';
		if (selectedOrderStatus !== LOADING && selectedOrderStatus !== INITIAL) {
			timeoutId = setTimeout(() => {
				if (isNaN(+orderId)) {
					dispatch(getAnonymousUserOrder(orderId));
				} else {
					if (accountDetails) dispatch(getUserOrder(+orderId));
				}
			}, ordersFetchIntervalInSeconds * 1000);
		}

		if (selectedOrderStatus === INITIAL) {
			if (isNaN(+orderId)) {
				dispatch(getAnonymousUserOrder(orderId));
			} else {
				if (accountDetails) dispatch(getUserOrder(+orderId));
			}
		}

		return () => {
			if (timeoutId !== '') {
				clearTimeout(timeoutId);
			}
		};
	}, [dispatch, selectedOrderStatus, orderId, accountDetails]);

	const renderDeliverySection = () => {
		let estDeliveryTime = '';
		if (selectedOrder.estimated_time_in_minutes) {
			estDeliveryTime = selectedOrder.estimated_time_in_minutes.toString() + ' minut';
		} else {
			estDeliveryTime = 'Oczekuje';
		}

		// odbiór własny
		if (selectedOrder.is_self_pickup) {
			return (
				<>
					<Typography.Text strong>Odbiór własny</Typography.Text>
					<br />
					<Typography.Text strong>Szacowany czas dostawy: {estDeliveryTime}</Typography.Text>
				</>
			);
		}
		// dowóz

		// console.log(selectedOrder.address1);
		// console.log(selectedOrder.address2);
		let address = '';
		if (selectedOrder.address1 && selectedOrder.address2) {
			address = `${selectedOrder.address1}/${selectedOrder.address2}`;
		} else if (selectedOrder.address1) {
			address = `${selectedOrder.address1}`;
		} else if (selectedOrder.address2) {
			address = `${selectedOrder.address2}`;
		}

		return (
			<>
				<Typography.Text strong>Dostawa na adres</Typography.Text>
				<br />
				<Typography.Text type='secondary'>
					{selectedOrder.city} {address}
				</Typography.Text>
				<br />
				<Typography.Text strong>Szacowany czas dostawy: {estDeliveryTime}</Typography.Text>
			</>
		);
	};

	const renderToPaySection = () => {
		if (selectedOrder.is_paid) {
			return (
				<Row>
					<Col xs={19} className='text-right'>
						<Typography.Text strong>Zapłacono</Typography.Text>
					</Col>
					<Col xs={5} className='text-right'>
						<Typography.Text strong type='success'>
							{(selectedOrder.price_pln + selectedOrder.delivery_price).toFixed(2)} zł
						</Typography.Text>
					</Col>
				</Row>
			);
		}

		return (
			<Row>
				<Col xs={19} className='text-right'>
					<Typography.Text strong>Do zapłaty</Typography.Text>
				</Col>
				<Col xs={5} className='text-right'>
					<Typography.Text strong type='danger'>
						{(selectedOrder.price_pln + selectedOrder.delivery_price).toFixed(2)} zł
					</Typography.Text>
				</Col>
			</Row>
		);
	};

	const renderOrderedProductsSection = () => {
		return (
			<Row>
				<Col xs={24}>
					<Row>
						<Col xs={14}>
							<Typography.Text type='secondary'>Zamówione produkty</Typography.Text>
						</Col>
						<Col xs={5} className='text-right'>
							<Typography.Text type='secondary'>Ilość</Typography.Text>
						</Col>
						<Col xs={5} className='text-right'>
							<Typography.Text type='secondary'>Cena</Typography.Text>
						</Col>
					</Row>
				</Col>
				<Col xs={24}>
					{selectedOrder.ordered_products.map((op) => {
						return (
							<Row key={op.id}>
								<Col xs={14}>
									<Typography.Text strong>{op.name}</Typography.Text>
								</Col>
								<Col xs={5} className='text-right'>
									<Typography.Text strong>{op.count}</Typography.Text>
								</Col>
								<Col xs={5} className='text-right'>
									<Typography.Text strong>{op.final_price_pln.toFixed(2)}</Typography.Text>
								</Col>
							</Row>
						);
					})}
				</Col>
				<Divider className='my-1' />
				<Col xs={24}>
					<Row>
						<Col xs={19} className='text-right'>
							<Typography.Text type='secondary'>Wartość zamówienia</Typography.Text>
						</Col>
						<Col xs={5} className='text-right'>
							<Typography.Text strong>
								{selectedOrder.original_price_in_pln.toFixed(2)} zł
							</Typography.Text>
						</Col>
					</Row>
				</Col>
				<Col xs={24}>
					<Row>
						<Col xs={19} className='text-right'>
							<Typography.Text type='secondary'>Dostawa</Typography.Text>
						</Col>
						<Col xs={5} className='text-right'>
							<Typography.Text strong>{selectedOrder.delivery_price.toFixed(2)} zł</Typography.Text>
						</Col>
					</Row>
				</Col>
				<Col xs={24}>
					<Row>
						<Col xs={19} className='text-right'>
							<Typography.Text type='secondary'>Zniżka</Typography.Text>
						</Col>
						<Col xs={5} className='text-right'>
							<Typography.Text strong>
								{(selectedOrder.price_pln - selectedOrder.original_price_in_pln).toFixed(2)} zł
							</Typography.Text>
						</Col>
					</Row>
				</Col>
				<Col xs={24}>{renderToPaySection()}</Col>
			</Row>
		);
	};

	const renderStepsSection = () => {
		const orderStatus = orderStatusMapper(selectedOrder.order_status.name);

		let currentStep = 0;

		// przesunięcie stepów jesli jest self pickup
		// poniewaz self-pickup i dostawa rozni sie o 1 krok,
		// to o ten krok trzeba poprawic wyswietlenie
		const offset = selectedOrder.is_self_pickup || selectedOrder.is_order_to_table ? 1 : 0;

		switch (orderStatus) {
			case OrderStatusEnum.new_order:
				currentStep = 0;
				break;

			case OrderStatusEnum.in_preparation:
				currentStep = 1;
				break;

			case OrderStatusEnum.cancel:
				currentStep = 3 - offset;
				break;

			case OrderStatusEnum.error:
				currentStep = 0;
				break;

			case OrderStatusEnum.in_transport:
				currentStep = 3;
				break;

			case OrderStatusEnum.finished:
				currentStep = 4 - offset;
				break;

			case OrderStatusEnum.paid:
				currentStep = 0;
				break;

			case OrderStatusEnum.ready:
				currentStep = 2;
				break;

			default:
				currentStep = 0;
				break;
		}

		if (selectedOrder.is_self_pickup || selectedOrder.is_order_to_table) {
			return (
				<Steps direction='vertical' size='small' current={currentStep}>
					<Steps.Step icon={<UnorderedListOutlined />} title='Złożone' />
					<Steps.Step icon={<SettingOutlined />} title='W przygotowaniu' />
					<Steps.Step icon={<LikeOutlined />} title='Gotowe do odbioru' />
					<Steps.Step icon={<HomeOutlined />} title='Zakończone' />
				</Steps>
			);
		} else {
			return (
				<Steps direction='vertical' size='small' current={currentStep}>
					<Steps.Step icon={<UnorderedListOutlined />} title='Złożone' />
					<Steps.Step icon={<SettingOutlined />} title='W przygotowaniu' />
					<Steps.Step icon={<LikeOutlined />} title='Gotowe do odbioru' />
					<Steps.Step icon={<CarOutlined />} title='W drodze' />
					<Steps.Step icon={<HomeOutlined />} title='Zakończone' />
				</Steps>
			);
		}
	};

	if (selectedOrderStatus === SUCCESS) {
		return (
			<Row justify='center' className='order-details-top-row'>
				<Col xs={22} lg={16} xl={12}>
					<Card>
						<Row justify='center'>
							<Col>
								<Typography.Title level={5}>{`Zamówienie ${selectedOrder.number}`}</Typography.Title>
							</Col>
						</Row>
						<Row justify='center'>
							<Col>{getLocalDateWithTime(selectedOrder.date_created)}</Col>
						</Row>
						<Row justify='center' className='pt-3'>
							<Col xs={24} md={8}>
								<Row justify='center'>
									<Col>{renderStepsSection()}</Col>
								</Row>
							</Col>
							<Col xs={24} md={16}>
								<Row>
									<Col xs={24}>
										<Typography.Text strong>{selectedOrder.place.name}</Typography.Text>
										<br />
										<Typography.Text type='secondary'>
											{selectedOrder.place.address}
										</Typography.Text>
									</Col>
								</Row>
								<Row className='pt-3'>
									<Col xs={24}>{renderDeliverySection()}</Col>
								</Row>
								<Row className='pt-3'>
									<Col xs={24}>{renderOrderedProductsSection()}</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		);
	} else if (selectedOrderStatus === FAILED) {
		return (
			<Result
				status='error'
				title='Wystąpił problem'
				extra={
					<Link to='/'>
						<Button type='primary' key='console'>
							Strona główna
						</Button>
					</Link>
				}
			/>
		);
	} else {
		return <LoadingScreen container='screen' />;
	}
};

export default OrderDetails;
