import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'App/state/root.reducer';
import Role from 'App/types/role';
import NotLoggedInNavbar from '../components/Navbar/NotLoggedInNavbar';
import ClientNavbar from '../components/Navbar/ClientNavbar';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavbarContainer: React.FC = () => {
	const accountDetails = useSelector((state: RootState) => state.account.accountDetails);

	const { t } = useTranslation('page');

	const restaurantMenu = (
		<Menu.Item key='/restaurant-menu'>
			<Link to='/restaurant-menu'>{t('Common.NavbarContainer.Menu')}</Link>
		</Menu.Item>
	);

	const promotions = (
		<Menu.Item key='/promotions'>
			<Link to='/promotions'>{t('Common.NavbarContainer.Promotions')}</Link>
		</Menu.Item>
	);

	const orders = (
		<Menu.Item key='/orders'>
			<Link to='/orders'>{t('Common.NavbarContainer.Orders')}</Link>
		</Menu.Item>
	);

	if (accountDetails) {
		if (accountDetails.user_role.includes(Role.CLIENT)) {
			return (
				<ClientNavbar
					accountDetails={accountDetails}
					orders={orders}
					promotions={promotions}
					restaurantMenu={restaurantMenu}
				/>
			);
		}
	} else {
		return <NotLoggedInNavbar promotions={promotions} restaurantMenu={restaurantMenu} />;
	}
};

export default NavbarContainer;
