import { Row, Col, Result, Button, Empty } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { getAllUserPromotions } from 'App/state/promotions/promotions.thunk';
import { RootState } from 'App/state/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PromotionDetails from '../promotionDetails/PromotionDetails';
import { getPromotionIdsToShowInPromotionsPanel } from '../utils/getPromotionIdsToShowInPromotionsPanel';
import PromotionListCard from './components/promotionListCard/PromotionListCard';
import './PromotionList.less';

const { SUCCESS, FAILED } = StatusType;

const PromotionsList = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const promotions = useSelector((state: RootState) => state.promotions.promotions);
	const promotionsStatus = useSelector((state: RootState) => state.promotions.status.getAllUserPromotions);

	const [promotionIdsToShow, setPromotionIdsToShow] = useState<number[]>([]);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [promotionToShowInModal, setPromotionToShowInModal] = useState<PromotionForGetAllUserPromotions>(null);

	useEffect(() => {
		dispatch(getAllUserPromotions());
	}, [dispatch]);

	useEffect(() => {
		setPromotionIdsToShow(getPromotionIdsToShowInPromotionsPanel(promotions));
	}, [promotions]);

	const showPromotionDetails = (promotion: PromotionForGetAllUserPromotions) => {
		setPromotionToShowInModal(promotion);
		setShowDetailsModal(true);
	};

	const closePromotionDetails = () => {
		setShowDetailsModal(false);
		setPromotionToShowInModal(null);
	};

	if (promotionsStatus === SUCCESS) {
		if (promotions.length > 0) {
			return (
				<>
					<Modal
						visible={showDetailsModal}
						destroyOnClose={true}
						onCancel={closePromotionDetails}
						onOk={closePromotionDetails}
						cancelText='Anuluj'
						closable={false}
					>
						<PromotionDetails promotion={promotionToShowInModal} />
					</Modal>
					<Row justify='center' className='promotions-list-top-row'>
						<Col xs={24} md={24} lg={20}>
							<Row>
								{promotionIdsToShow.map((id) => {
									const promotion = promotions.find((p) => p.id === id);
									return (
										<Col key={id} xs={24} sm={12} md={8} xl={8} xxl={6} className='px-3 py-2'>
											<div onClick={() => showPromotionDetails(promotion)}>
												<PromotionListCard promotion={promotion} />
											</div>
										</Col>
									);
								})}
							</Row>
						</Col>
					</Row>
				</>
			);
		} else {
			return (
				<Empty
					className='pt-5'
					description={'Brak promocji'}
					image={require('../../../common/assets/empty-placeholder-bw.jpg')}
					imageStyle={{
						height: 60,
					}}
				/>
			);
		}
	} else if (promotionsStatus === FAILED) {
		return (
			<Result
				status='error'
				title='Wystąpił problem'
				extra={
					<Button type='primary' key='console' onClick={() => history.push('/')}>
						Strona główna
					</Button>
				}
			/>
		);
	} else {
		return <LoadingScreen container='screen' />;
	}
};

export default PromotionsList;
