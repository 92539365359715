import React, { useEffect, useState } from 'react';
import { Button, Col, notification, Result, Row, Steps, Tooltip } from 'antd';

import StepCart from './components/steps/stepCart/StepCart';
import StepDelivery from './components/steps/stepDelivery/StepDelivery';
import StepSummary from './components/steps/stepSummary/StepSummary';

import './CartPageContainer.less';
import { RootState } from 'App/state/root.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isDeliveryStep, isLastStep, isNotFirstStep } from './components/utils/stepUtils';
import { DeliveryMethodEnum } from 'App/domain/enums/deliveryMethodEnum';
import { addNewAnonymousOrder, addNewOrder } from 'App/state/newOrder/newOrder.thunk';
import { localStorageHelper } from 'App/common/utils/localStorageHelper';
import StatusType from 'App/types/requestStatus';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { isRestaurantOpen } from '../RestaurantsPage/restaurantsList/utils/isRestaurantOpen';
import { mapRestaurantToResponseType } from '../RestaurantsPage/restaurantsList/utils/mapRestaurantToResponseType';

const { Step } = Steps;

const steps = [
	{
		title: 'Koszyk',
		content: <StepCart />,
	},
	{
		title: 'Dostawa',
		content: <StepDelivery />,
	},
	{
		title: 'Podsumowanie',
		content: <StepSummary />,
	},
];

const CartPageContainer = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const [current, setCurrent] = React.useState(0);

	const newOrderState = useSelector((state: RootState) => state.newOrder);
	const accountDetails = useSelector((state: RootState) => state.account.accountDetails);

	const cart = useSelector((state: RootState) => state.newOrder.cart);
	const isAddressOnDeliveryZones = useSelector((state: RootState) => state.newOrder.isAddressOnDeliveryZones);
	const deliveryMethodEnum = useSelector((state: RootState) => state.newOrder.deliveryMethodEnum);
	const changingDeliveryOrAddress = useSelector((state: RootState) => state.newOrder.changingDeliveryOrAddress);
	const addNewOrderStatus = useSelector((state: RootState) => state.newOrder.status.addNewOrderStatus);
	const anonymousClientData = useSelector((state: RootState) => state.newOrder.anonymousClientData);

	const sendOrder = () => {
		const onSuccess = () => {
			localStorageHelper.clear();
			notification['success']({
				message: 'Sukces',
				description: 'Wysłano zamówienie!',
			});
		};

		// jeśli user zalogowany
		if (accountDetails) {
			dispatch(addNewOrder(newOrderState, onSuccess, history));
		} else {
			dispatch(addNewAnonymousOrder(newOrderState, anonymousClientData, onSuccess, history));
		}
	};

	const [isAnonymousOrderAndNoUserDataProvided, setIsAnonymousOrderAndNoUserDataProvided] = useState(true);
	const [isLoggedUserDataProvided] = useState(
		accountDetails === null ||
			(accountDetails !== null &&
				accountDetails.client_information.name !== null &&
				accountDetails.client_information.last_name !== null &&
				accountDetails.client_information.phone_number !== null)
	);

	useEffect(() => {
		if (accountDetails) {
			setIsAnonymousOrderAndNoUserDataProvided(false);
		} else {
			// sprawdź czy formularz z danymi użytkownika został wypełniony
			if (anonymousClientData) {
				setIsAnonymousOrderAndNoUserDataProvided(false);
			} else {
				setIsAnonymousOrderAndNoUserDataProvided(true);
			}
		}
	}, [accountDetails, anonymousClientData]);

	const getFirstStepButton = () => {
		const restaurantOpen = isRestaurantOpen(mapRestaurantToResponseType(newOrderState.restaurant) as any);

		if (!restaurantOpen)
			return (
				// <Tooltip title='Restauracja jest zamknięta'>
				// 	<Button type='primary' disabled className='cart-page-container-next-button'>
				// 		Dalej
				// 	</Button>
				// </Tooltip>
				<Tooltip title='Przyjmujemy zamówienia telefoniczne +48 514 734 702'>
					<Button type='primary' disabled className='cart-page-container-next-button'>
						Dalej
					</Button>
				</Tooltip>
			);

		if (isLoggedUserDataProvided)
			return (
				<Button
					type='primary'
					onClick={() => setCurrent(current + 1)}
					className='cart-page-container-next-button'
					disabled={!isLoggedUserDataProvided}
				>
					Dalej
				</Button>
			);

		return (
			<Tooltip title='Aby kontynuować uzupełnij swoje dane w zakładce "Profil" (imię, nazwisko, nr. telefonu)'>
				<Button type='primary' disabled className='cart-page-container-next-button'>
					Dalej
				</Button>
			</Tooltip>
		);
	};

	return (
		<>
			{addNewOrderStatus === StatusType.LOADING || addNewOrderStatus === StatusType.SUCCESS ? (
				<LoadingScreen container='screen' />
			) : (
				<Row justify='center' align='middle'>
					{cart?.cart_products?.length > 0 ? (
						<>
							<Col xs={24} md={6} className='my-3 my-md-0'>
								<Row justify='center'>
									<Col>
										<Steps current={current} direction='vertical'>
											{steps.map((item) => (
												<Step key={item.title} title={item.title} />
											))}
										</Steps>
									</Col>
								</Row>
							</Col>

							<Col xs={24} md={18}>
								<Row className='steps-content'>{steps[current].content}</Row>
								<Row className='steps-action mt-2'>
									<Col xs={24} xl={22} xxl={20}>
										<Row justify='center'>
											<Col xs={24} md={19} className='text-center'>
												{isNotFirstStep(current) && (
													<Button
														className='cart-page-container-previous-button'
														onClick={() => setCurrent(current - 1)}
													>
														Wstecz
													</Button>
												)}

												{/* 2nd step */}
												{!isLastStep(current, steps) && isDeliveryStep(current) && (
													<Button
														type='primary'
														onClick={() => setCurrent(current + 1)}
														className='cart-page-container-next-button'
														disabled={
															(deliveryMethodEnum === DeliveryMethodEnum.delivery &&
																!isAddressOnDeliveryZones) ||
															isAnonymousOrderAndNoUserDataProvided
														}
														loading={changingDeliveryOrAddress}
													>
														Dalej
													</Button>
												)}

												{/* 1st step */}
												{!isLastStep(current, steps) &&
													!isDeliveryStep(current) &&
													getFirstStepButton()}
												{/* 3rd step */}
												{isLastStep(current, steps) && (
													<Button
														type='primary'
														onClick={() => sendOrder()}
														className='cart-page-container-order-button'
													>
														Zamów
													</Button>
												)}
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</>
					) : (
						<Col>
							<Result
								icon={<InfoCircleOutlined />}
								title='Brak produktów w koszyku'
								subTitle='Dodaj produkty do koszyka'
								extra={
									<Link to='/restaurant-menu'>
										<Button type='primary' size='large'>
											Wybierz potrawy z restauracji
										</Button>
									</Link>
								}
							/>
						</Col>
					)}
				</Row>
			)}
		</>
	);
};

export default CartPageContainer;
